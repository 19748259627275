import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../helpers/axios';
import Layout from './layout';
import {
  Avatar,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Checkbox,
  Button,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import WebIcon from '@mui/icons-material/Web';

const defaultProfilePicture = "https://via.placeholder.com/150";

const UserDetailPage = () => {
  const { id } = useParams(); 
  const [user, setUser] = useState(null);
  const [sectorName, setSectorName] = useState('');
  
  const [permissions, setPermissions] = useState({
    users: { manageUsers: 
      { view: false, 
        create: false,
         update: false, 
         delete: false, 
         assignRoles: false, 
         grantPermissions: false }
     },
    innovations: { manageInnovations: 
      { view: false, 
        share: false, 
        favorite: false, 
        create: false,
         update: false,
          delete: false, 
          suspend: false, 
          archive: false, 
          approve: false } 
    }
  });

  const [editMode, setEditMode] = useState(false); 

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await axiosInstance.get(`users/getbyId/${id}`);
        setUser(userResponse.data);

        if (userResponse.data.role === 'innovator') {
          const sectorResponse = await axiosInstance.get(`sectors/${userResponse.data.sectorId}`);
          setSectorName(sectorResponse.data.name);
        }

        setPermissions({
          users: userResponse.data.permissions?.manageUsers || { view: false, create: false, update: false, delete: false, assignRoles: false, grantPermissions: false },
          innovations: userResponse.data.permissions?.manageInnovations || { view: false, share: false, favorite: false, create: false, update: false, delete: false, suspend: false, archive: false, approve: false },
        });
        
      } catch (error) {
        console.error('Error fetching user or permissions:', error);
      }
    };

    fetchUser();
  }, [id]);


  const handleCheckboxChange = (category, permission) => (event) => {
    setPermissions((prevPermissions) => {
      if (!prevPermissions[category]) {
        console.error(`Category ${category} is undefined`);
        return prevPermissions;
      }
  
      // Debugging the state change
      console.log(`Updating permission for ${category}: ${permission} = ${event.target.checked}`);
    
      return {
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          [permission]: event.target.checked,
        },
      };
    });
  };
  

 

  const toggleEditMode = () => {
    setEditMode((prevEditMode) => !prevEditMode); 
  };

  const handleSaveChanges = async () => {
    try {
      const updatedUser = {
        fullName: user.fullName,
        email: user.email,
        role: user.role,
        permissions: permissions,
      };

      await axiosInstance.put(`users/updateUser/${id}`, updatedUser);
      alert('User updated successfully!');
      toggleEditMode(); // Exit edit mode after saving
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user!');
    }
  };


  const permissionCategories = {
    Innovations: {
      permissions: permissions.innovations || {},
      actions: ['view', 'share', 'favorite', 'create', 'update', 'delete', 'suspend', 'archive', 'approve'],
    },
    Users: {
      permissions: permissions.users || {},
      actions: ['view', 'create', 'update', 'delete', 'assignRoles', 'grantPermissions'],
    },
  };

  if (!user) {
    return (
      <Layout>
          <Grid container spacing={3} style={{ marginTop: '100px', marginLeft: '45px', marginBottom: '100px' }}>
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
        </Grid>
      </Layout>
    );
  }



  return (
    <Layout>
      <Grid container spacing={3} style={{ marginTop: '100px', marginLeft: '45px', marginBottom: '100px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card elevation={0} sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      src={user.profilePicture || defaultProfilePicture}
                      alt="Profile Image"
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{user.fullName}</Typography>
                    <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                      @{user.role}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card elevation={0} sx={{ marginTop: '20px', border: '1px solid #ddd', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">About</Typography>
                <Typography variant="body1">{user.bio || "No bio available"}</Typography>
              </CardContent>
            </Card>
            {user.role === 'innovator' && (
              <Card elevation={0} sx={{ marginTop: '20px', border: '1px solid #ddd', borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6">Company Details</Typography>
                  <Box display="flex" alignItems="center" mb={1}>
                    <BusinessIcon sx={{ marginRight: '8px' }} />
                    <Typography variant="body1">{user.businessName || "No business details available"}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <WorkIcon sx={{ marginRight: '8px' }} />
                    <Typography variant="body1">{sectorName || "No sector details available"}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <WebIcon sx={{ marginRight: '8px' }} />
                    <Typography variant="body1">{user.web || "No website details available"}</Typography>
                  </Box>
                  <hr />
                  <p>ELEVATOR PITCH</p>
                  <hr />
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="body1">{user.elevatorPitch || "No elevator pitch available"}</Typography>
                  </Box>
                </CardContent>
              </Card>
            )}
            <Card sx={{ marginTop: '20px', border: '1px solid #ddd', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">Contact</Typography>
                <Box>
                  {user.email && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <EmailIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.email}</Typography>
                    </Box>
                  )}
                  {user.phone && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <PhoneIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.phone}</Typography>
                    </Box>
                  )}
                  {user.city && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <LocationCityIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.city}</Typography>
                    </Box>
                  )}
                  {user.address && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <HomeIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.address}</Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Permissions</Typography>
            <Button onClick={toggleEditMode} color="primary" sx={{ marginTop: '10px' }}>
              {editMode ? 'Cancel' : 'Edit Permissions'}
            </Button>
            {Object.keys(permissionCategories).map((category) => (
              <Box key={category} sx={{ border: '1px solid #ddd', borderRadius: 2, marginTop: '20px' }}>
                <CardContent>
                  <Typography variant="h6">{category}</Typography>
                  <Grid container spacing={2}>
                    {permissionCategories[category].actions.map((permission) => (
                      <Grid item xs={6} md={4} key={permission}>
                        <Checkbox
                          checked={permissions[category.toLowerCase()][permission] || false}
                          onChange={handleCheckboxChange(category.toLowerCase(), permission)}
                          disabled={!editMode}
                        />
                        <Typography variant="body1">{permission}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Box>
            ))}
            {editMode && (
              <Button onClick={handleSaveChanges} color="primary" variant="contained" sx={{ marginTop: '10px' }}>
                Save Permissions
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default UserDetailPage;
