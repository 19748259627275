import React, { useState, useEffect } from 'react';
import axios from '../../helpers/axios';
import Layout from './layout';
import {
  Box,
  Typography,
  Button,
  Grid,
  Avatar,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const placeholderImage = 'img/placeholder.jpg';
const roles = ['All', 'admin', 'manager', 'innovator', 'user', 'partner', 'investor'];

const ManagerUsers = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [selectedRole, setSelectedRole] = useState('All');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmrDialog, setOpenConfirmrDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [newUser, setNewUser] = useState({
    fullName: '',
    email: '',
    role: '',
  });

  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
const [editUser, setEditUser] = useState({});

const [stats, setStats] = useState({
  innovatorsCount: 0,
  investorsCount: 0,
  innovationsCount: 0,
  partnersCount: 0,
});

// Function to fetch statistics
const fetchStatistics = async () => {
  try {
    const [innovatorsResponse, investorsResponse, innovationsResponse, partnersResponse] = await Promise.all([
      axios.get('https://api.ghsdginnovations.org/api/users/innovators/count'),
      axios.get('https://api.ghsdginnovations.org/api/users/investors/count'),
      axios.get('https://api.ghsdginnovations.org/api/innovations/approved/count'),
      axios.get('https://api.ghsdginnovations.org/api/users/partners/count'),
    ]);

    setStats({
      innovatorsCount: innovatorsResponse.data.count,
      investorsCount: investorsResponse.data.count,
      innovationsCount: innovationsResponse.data.count,
      partnersCount: partnersResponse.data.count,
    });
  } catch (error) {
    console.error('Error fetching statistics:', error);
  }
};

useEffect(() => {
  fetchStatistics();
}, []);


  const handleProfileClick = (userId) => {
    navigate(`/manageUsers/${userId}`);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('admin/users');
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response?.status === 401) {
        alert("Session expired. Please log in again.");
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => {
      const matchesRole = selectedRole === 'All' || user.role === selectedRole;
      const matchesFilter = user.fullName?.toLowerCase().includes(filter.toLowerCase() || '');
      return matchesRole && matchesFilter;
    });
    setFilteredUsers(filtered);
  }, [filter, selectedRole, users]);

  const handleFilterChange = (event) => setFilter(event.target.value);
  
  const handleRoleChange = (event, newValue) => {
    setSelectedRole(roles[newValue]);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddUserDialogOpen = () => setOpenAddUserDialog(true);
  
  const handleAddUserDialogClose = () => setOpenAddUserDialog(false);

  const handleAddUser = async () => {
    try {
      const response = await axios.post('users/add', newUser);
      if (response.data) {
        setUsers(prevUsers => [...prevUsers, response.data]);
        setFilteredUsers(prevFiltered => [...prevFiltered, response.data]);

        swal({
          icon: 'success',
          title: 'User Added!',
          text: `New user ${newUser.fullName} has been added successfully.`,
          confirmButtonText: 'Okay',
        }).then(() => {
          navigate('/manageUsers'); // Update with the actual path
        });
      }
    } catch (error) {
      console.error('Error adding new user:', error);
      if (error.response?.status === 401) {
        alert("Session expired. Please log in again.");
      }
    } finally {
      setOpenAddUserDialog(false);
      setNewUser({ fullName: '', email: '', role: '' }); // Reset to defaults
    }
  };


    // Function to open the edit dialog
const handleEditUserDialogOpen = (user) => {
  setEditUser(user);
  setOpenEditUserDialog(true);
};

// Function to handle the editing process
const handleEditUser = async () => {
  try {
    const response = await axios.put(`users/${editUser._id}/edit`, editUser);
    if (response.data) {
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user._id === editUser._id ? { ...user, ...editUser } : user
        )
      );
      setFilteredUsers(prevFiltered => 
        prevFiltered.map(user => 
          user._id === editUser._id ? { ...user, ...editUser } : user
        )
      );

      swal({
        icon: 'success',
        title: 'User Updated!',
        text: `User ${editUser.fullName} has been updated successfully.`,
        confirmButtonText: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error updating user:', error);
    if (error.response?.status === 401) {
      alert("Session expired. Please log in again.");
    }
  } finally {
    setOpenEditUserDialog(false);
    setEditUser({}); // Reset to defaults
  }
};

const handleDeleteUserDialogOpen = (user) => {
  setSelectedUser(user);
  setOpenConfirmDialog(true);
};

const handleDeleteUser = async () => {
  if (selectedUser) {
    try {
      // Make the delete request with the correct URL
      await axios.delete(`users/${selectedUser._id}/delete`, {
        data: { isAdmin: true } // Include the request body with isAdmin
      });

      // Update state to remove the deleted user
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== selectedUser._id));
      setFilteredUsers((prevFiltered) => prevFiltered.filter((user) => user._id !== selectedUser._id));

      // Show success message
      swal({
        icon: 'success',
        title: 'User Deleted!',
        text: `User ${selectedUser.fullName} has been deleted successfully.`,
        confirmButtonText: 'Okay',
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      if (error.response?.status === 401) {
        alert("Session expired. Please log in again.");
      } else {
        alert("An error occurred while deleting the user.");
      }
    } finally {
      setOpenConfirmDialog(false);
      setSelectedUser(null);
    }
  }
};
  const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangeRole = (user, role) => {
    setSelectedUser(user);
    setNewRole(role);
    setOpenConfirmrDialog(true);
  };

  const handleConfirmRoleChange = async () => {
    if (selectedUser && newRole) {
      try {
        const response = await axios.put(`users/changeRole/${selectedUser._id}`, { newRole });
        setUsers(prevUsers => 
          prevUsers.map(user => 
            user._id === selectedUser._id ? { ...user, role: newRole } : user
          )
        );
        setFilteredUsers(prevFiltered => 
          prevFiltered.map(user => 
            user._id === selectedUser._id ? { ...user, role: newRole } : user
          )
        );

        swal({
          icon: 'success',
          title: 'Role Updated!',
          text: `User role changed to ${newRole} successfully.`,
          confirmButtonText: 'Okay',
        });
      } catch (error) {
        console.error('Error updating user role:', error);
        if (error.response?.status === 401) {
          alert("Session expired. Please log in again.");
        }
      } finally {
        setOpenConfirmrDialog(false);
      }
    }
  };

  return (
    <Layout>
      <Box marginTop="100px" marginBottom="80px">
        <Typography variant="h6" gutterBottom>
          Manage Users
        </Typography>
        <Button variant="contained" onClick={handleAddUserDialogOpen}>Add New User</Button>


        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  marginBottom: '10px',
                  border: '1px solid #dcdcdc',
                }}
              >
                <ListItemAvatar>
                  <Avatar alt="Avatar" src="/static/innovator.webp" />
                </ListItemAvatar>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography component="h2">Innovators</Typography>
                      <Typography variant="h6" component="h2">{stats.innovatorsCount}</Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  marginBottom: '10px',
                  border: '1px solid #dcdcdc',
                }}
              >
                <ListItemAvatar>
                  <Avatar alt="Avatar" src="/static/investor.png" />
                </ListItemAvatar>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography component="h2">Investors</Typography>
                      <Typography variant="h6" component="h2">{stats.investorsCount}</Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  marginBottom: '10px',
                  border: '1px solid #dcdcdc',
                }}
              >
                <ListItemAvatar>
                  <Avatar alt="Avatar" src="/static/partner.png" />
                </ListItemAvatar>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography component="h2">Partners</Typography>
                      <Typography variant="h6" component="h2">{stats.partnersCount}</Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Tabs value={roles.indexOf(selectedRole)} onChange={handleRoleChange} aria-label="role filter tabs">
          {roles.map((role) => (
            <Tab key={role} label={role.charAt(0).toUpperCase() + role.slice(1)} />
          ))}
        </Tabs>

        <Box marginTop="10px">
          <TextField
            id="filter"
            label="Filter by name"
            value={filter}
            onChange={handleFilterChange}
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{ maxWidth: 400 }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Avatar
                        src={user.profilePicture || placeholderImage}
                        sx={{ width: 30, height: 30, marginRight: '10px' }}
                      />
                      {user.fullName}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      SelectProps={{ native: true }}
                      value={user.role}
                      onChange={(e) => handleChangeRole(user, e.target.value)}
                    >
                      {roles.slice(1).map((role) => (
                        <option key={role} value={role}>{role}</option>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                  <Button onClick={() => handleProfileClick(user._id)}>
                      View
                    </Button>
                    <Button onClick={() => handleEditUserDialogOpen(user)}>
  Edit
</Button>
                    <Button onClick={() => handleDeleteUserDialogOpen(user)} color="error">
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      <Dialog open={openConfirmrDialog} onClose={() => setOpenConfirmrDialog(false)}>
        <DialogTitle>Confirm Role Change</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to change the role of {selectedUser?.fullName}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmrDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmRoleChange} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddUserDialog} onClose={handleAddUserDialogClose}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            fullWidth
            variant="outlined"
            value={newUser.fullName}
            onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            variant="outlined"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            label="Role"
            fullWidth
            margin="normal"
            select
            SelectProps={{ native: true }}
            value={newUser.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
          >
            <option value="" disabled></option>
            {roles.slice(1).map((role) => (
              <option key={role} value={role}>{role}</option>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddUserDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleAddUser} color="primary">Add User</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditUserDialog} onClose={() => setOpenEditUserDialog(false)}>
  <DialogTitle>Edit User</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Modify the details of the user.
    </DialogContentText>
    <TextField
      autoFocus
      margin="dense"
      label="Full Name"
      type="text"
      fullWidth
      variant="outlined"
      value={editUser.fullName}
      onChange={(e) => setEditUser({ ...editUser, fullName: e.target.value })}
    />
    <TextField
      margin="dense"
      label="Email"
      type="email"
      fullWidth
      variant="outlined"
      value={editUser.email}
      onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
    />
    <TextField
      select
      SelectProps={{ native: true }}
      margin="dense"
      label="Role"
      fullWidth
      variant="outlined"
      value={editUser.role}
      onChange={(e) => setEditUser({ ...editUser, role: e.target.value })}
    >
      {roles.slice(1).map((role) => (
        <option key={role} value={role}>{role}</option>
      ))}
    </TextField>

  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenEditUserDialog(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={handleEditUser} color="primary">
      Update User
    </Button>
  </DialogActions>
</Dialog>
       {/* Confirmation Dialog for Deletion */}
       <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete {selectedUser?.fullName}?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteUser} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

    </Layout>
  );
};

export default ManagerUsers;
