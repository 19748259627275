import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';
import Layout from './layout';

const AuditTrail = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLogs, setTotalLogs] = useState(0);

  // Fetch audit trail data
  useEffect(() => {
    const fetchAuditLogs = async () => {
      try {
        const response = await axios.get(`/api/audit-trail`, {
          params: { page, rowsPerPage },
        });
        setAuditLogs(response.data.logs);
        setTotalLogs(response.data.total);
      } catch (error) {
        console.error('Error fetching audit logs:', error);
      }
    };

    fetchAuditLogs();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Layout>
  <Box marginTop='64px' marginBottom='30px'>
      <Typography variant="h4" gutterBottom>
        User Logs
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell><strong>User</strong></TableCell>
              <TableCell><strong>Action</strong></TableCell>
              <TableCell><strong>Details</strong></TableCell>
              <TableCell><strong>Timestamp</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
           
              <TableRow >
                <TableCell>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography></Typography>
                      <Typography variant="body2" color="textSecondary">
                     
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalLogs}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
    </Layout>
  );
};

export default AuditTrail;
