import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { signout } from "../../actions";
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Divider,
  Tooltip,
  Box // Import Box component
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from "../../helpers/axios";
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmailIcon from '@mui/icons-material/Email';

const placeholderImage = 'img/placeholder.jpg'
const Header = ({ open, handleDrawerOpen, userId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const logout = () => {
    dispatch(signout());
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`/users/profile/${userId}`);
        const data = response.data; // Use response.data to get the actual data
        setProfileData(data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [userId]);

  const drawerContent = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Tooltip title="Home" arrow sx={{ color: '#0468B1' }}>
          <Box sx={{ '&:hover': { color: 'white' } }}>
            <ListItem button component={Link} to="/main">
              <ListItemIcon sx={{ color: 'inherit' }}>
                <HomeIcon />
              </ListItemIcon>
            
            </ListItem>
          </Box>
        </Tooltip>
        <Tooltip title="Dashboard" arrow sx={{ color: '#0468B1' }}>
          <Box sx={{ '&:hover': {  color: 'white' } }}>
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon sx={{ color: 'inherit' }}>
                <DashboardIcon />
              </ListItemIcon>
             
            </ListItem>
          </Box>
        </Tooltip>
        <Tooltip title="My Innovations" arrow sx={{ color: '#0468B1' }}>
          <Box sx={{ '&:hover': {  color: 'white' } }}>
            <ListItem button component={Link} to="/innovations">
              <ListItemIcon sx={{ color: 'inherit' }}>
                <EmojiObjectsIcon />
              </ListItemIcon>
             
            </ListItem>
          </Box>
        </Tooltip>
        <Tooltip title="Investors" arrow sx={{ color: '#0468B1' }}>
          <Box sx={{ '&:hover': { backgroundColor: '#0468B1', color: 'white' } }}>
            <ListItem button component={Link} to="/investors">
              <ListItemIcon sx={{ color: 'inherit' }}>
                <GroupIcon />
              </ListItemIcon>
             
            </ListItem>
          </Box>
        </Tooltip>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" elevation={1} color='inherit' sx={{ backgroundColor: 'white' }}>
      <Toolbar sx={{ justifyContent: isMobile ? 'space-between' : 'flex-end' }}>
        {isMobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div style={{ marginRight: 'auto' }}>
            <Typography className="mr-4 ml-2 cursor-pointer py-1.5 font-bold">
            <img src="https://digitalx.undp.org/images/630478c456d6a9a3e003e9f2_logo-blue.svg" alt="Logo" style={{ width: '54px', height: '110px' }} />
            </Typography>
          </div>
        )}
        {isMobile ? (
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {drawerContent}
          </SwipeableDrawer>
        ) : (
          <List sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            
            <Tooltip title="Dashboard" arrow sx={{ color: '#0468B1' }}>
              <Box sx={{ '&:hover': {  color: 'white' } }}>
                <ListItem button component={Link} to="/dashboard">
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <DashboardIcon />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>
            <Tooltip title="Manage Innovations" arrow sx={{ color: '#0468B1' }}>
              <Box sx={{ '&:hover': { color: 'white' } }}>
                <ListItem button component={Link} to="/innovations">
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <EmojiObjectsIcon />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>
        
            <Tooltip title="User Management" arrow sx={{ color: '#0468B1' }}>
              <Box sx={{ '&:hover': {  color: 'white' } }}>
                <ListItem button component={Link} to="/manageUsers">
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    <GroupIcon />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>
          </List>
        )}
        {!isMobile && (
          <>
         
            
            <IconButton color="inherit" onClick={handleClick}>
              <Avatar alt="User" src={profileData ? profileData.profilePicture : placeholderImage } />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleUserMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleUserMenuClose}>
                <ListItem button component={Link} to="/myprofile">
                  <ListItemIcon>
                    <Avatar alt="Settings" src={profileData ? profileData.profilePicture : placeholderImage} />
                  </ListItemIcon>
                  <Divider orientation="vertical" flexItem />
                  <ListItemText primary={profileData ? profileData.fullName : "Settings"} />
                </ListItem>
              </MenuItem>
              <Divider orientation="horizontal" flexItem />
             
        
              <MenuItem onClick={handleUserMenuClose}>
                <ListItem button component={Link} to="/message">
                  <ListItemIcon>
                  <Badge badgeContent={0} color="error">
                                    <EmailIcon />
                                </Badge>
                  </ListItemIcon>
                  <Divider orientation="vertical" flexItem />
                  <ListItemText primary={ "Inbox"} />
                </ListItem>
              </MenuItem>
              <MenuItem onClick={handleUserMenuClose}>
                <ListItem button component={Link} to="/myprofile">
                  <ListItemIcon>
                    <Avatar alt="Settings"  />
                  </ListItemIcon>
                  <Divider orientation="vertical" flexItem />
                  <ListItemText primary={ "Settings"} />
                </ListItem>
              </MenuItem>
              <MenuItem onClick={handleUserMenuClose}>
                <ListItem button component={Link} to="/AuditTrail">
                  <ListItemIcon>
                    <Avatar alt="Activity Log"  />
                  </ListItemIcon>
                  <Divider orientation="vertical" flexItem />
                  <ListItemText primary={ "Activity Log"} />
                </ListItem>
              </MenuItem>
              <MenuItem onClick={handleUserMenuClose}>
                <ListItem button onClick={logout}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
